<template>
  <button class="phone-button" @click="onClick" :disabled="disabled">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'SmPhoneButton',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
.phone-button {
  width: 60px;
  margin: 0;
  height: 60px;

  font-size: 20px;

  border: 1px solid var(--white);

  cursor: pointer;

  &:hover {
    color: var(--gray);
  }

  &:active {
    color: var(--blue);
  }
  // &:disabled {
  //   color: var(--gray);
  //   background-color: var(--gray);
  // }
}
</style>
