<template>
  <section class="phone-buttons">
    <sm-phone-button
      v-for="number in 9"
      :key="'button_' + number"
      @click="onNumber(number)"
    >
      {{ number }}
    </sm-phone-button>
    <sm-phone-button
      class="phone-button--apply"
      :disabled="disabledCallButton"
      @click="onCall"
    >
      <sm-icon name="phone" class="phone-icon phone-icon--apply" />
    </sm-phone-button>
    <sm-phone-button @click="onNumber('0')">0</sm-phone-button>
    <sm-phone-button class="phone-button--cancel" @click="onCancel"
      ><sm-icon name="phone-x-mark" class="phone-icon phone-icon--cancel"
    /></sm-phone-button>
  </section>
</template>

<script>
import SmPhoneButton from '@/components/phone/SmPhoneButton.vue';
import SmIcon from '@/components/common/SmIcon.vue';

export default {
  name: 'SmPhoneButtons',

  props: {
    disabledCallButton: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    SmPhoneButton,
    SmIcon,
  },

  methods: {
    onNumber(number) {
      this.$emit('number', number);
    },

    onCall() {
      this.$emit('call');
    },

    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
.phone-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  width: 180px;
}

.phone-button--apply {
  background-color: rgb(var(--rgb-green), 0.1);

  &:active {
    color: var(--blue);
  }
}

.phone-button--cancel {
  background-color: rgb(var(--rgb-red), 0.1);

  &:active {
    color: var(--blue);
  }
}

.phone-icon--apply {
  fill: var(--green);
}

.phone-icon--cancel {
  fill: var(--red);
}

.phone-button:disabled {
  background-color: var(--gray);
}

.phone-button:disabled .phone-icon--apply {
  fill: rgb(var(--rgb-gray), 0.1);
}
</style>
