<template>
  <svg
    v-html="path"
    :class="$attrs.class || 'icon'"
    xmlns="http://www.w3.org/2000/svg"
    v-bind="svgAttributes"
  ></svg>
</template>

<script>
import icons from '@/utils/icons.js';
export default {
  name: 'smIcon',

  props: {
    name: {
      type: String,
      require: true,
    },

    outline: {
      type: Boolean,
      default: false,
    },

    strokeWidth: {
      type: [Number, String],
      default: 1.5,
    },
  },

  data() {
    return {
      outlineSvg: {
        fill: 'none',
        viewBox: '0 0 24 24',
        stroke: 'currentColor',
        'stroke-width': this.strokeWidth,
      },

      inlineSvg: {
        fill: 'currentColor',
        viewBox: '0 0 24 24',
      },
    };
  },

  computed: {
    path() {
      return this.outline ? icons.outline[this.name] : icons.inline[this.name];
    },

    svgAttributes() {
      return this.outline ? this.outlineSvg : this.inlineSvg;
    },
  },
};
</script>

<style lang="scss">
.icon {
  width: 24px;
  height: 24px;
}
</style>
